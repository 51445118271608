import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { SEO, Container } from '@components';

import * as s from '../styles/about.module.scss';

function AboutPage() {
    return (
        <Container className={s.container}>
            <SEO title="Home" />
            <div className={s.imageWrapper}>
                <StaticImage
                    src="../images/jason.jpeg"
                    alt="Jason, owner of Peace of Mind"
                    layout="constrained"
                    aspectRatio={2 / 3}
                />
            </div>
            <div className={s.text}>
                <h1>
                    About <strong>Peace of Mind</strong>
                </h1>
                <p>
                    Peace of Mind Contracting offers general contracting services with a focus on remodeling and
                    renovation projects. The locally owned company has built its reputation on the core values of
                    integrity, honesty, and reliability while providing excellent work and customer satisfaction. We
                    specialize in design, function, space maximization, and finishing touches. Our mission is to
                    recognize the visions of our clients and turn them into reality. From simple designs to extravagant
                    remodels, we are up to the challenge. A remodel of any scope should be a hassle-free and enjoyable
                    experience. Peace of Mind Contracting will take your project from start to finish while giving you
                    peace of mind during the entire process.
                </p>
                <p>
                    We adhere to all OSHA regulations and will obtain any required permits needed for your scope of
                    work. Our services are fully licensed, bonded and insured.
                </p>
            </div>
        </Container>
    );
}

export default AboutPage;
